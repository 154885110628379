@import "~react-image-gallery/styles/css/image-gallery.css";

* {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.tableView .MuiTableCell-head {
  font-weight: bold
}

.MuiContainer-maxWidthLg.print {
  max-width: none;
}

.MuiAlert-message {
  padding: 8px 0;
}

/* ========= */
.chartCustomTooltip {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  background-color: #ffffff;
  border-radius: 5px;
  padding: 8px 10px;
}

.chartCustomTooltip p {
  margin: 0;
}

.chartCustomTooltip .chartTitle {
  color: #4C5D6C;
  font-weight: 600;
  font-size: 0.9rem;
}

.chartCustomTooltip .chartBody {
  color: #4C5D6C;
  font-weight: 500;
  font-size: 0.9rem;
}
/* .makeStyles-container-17 {
  height: 80vh !important;
} */

/* ========= */
.print {
  background-color: #ffffff;
}

.print [class*="makeStyles-container"] {
  height: auto;
  overflow: initial;
}

.print [class*="sourceInfoSection"] {
  display: none;
}

.print [class*="termsAndConditionSec"] {
  display: none;
}

.print [class*="pageHeader"] {
  display: none;
}

.print [class*="editIconBtn"] {
  display: none;
}

.print .MuiButton-containedPrimary {
  display: none;
}

.print .MuiTypography-h6 {
  white-space: nowrap;
}

.print [class*="catChipView"] {
  white-space: nowrap;
}

.print [class*="dataValue"] {
  white-space: nowrap;
}

.print [class*="dataTitle"] {
  white-space: nowrap;
}

.print [class*="projectPriceView"] {
  white-space: nowrap;
}

.print [class*="applicatioNoView"] {
  white-space: nowrap;
}

.print .MuiTableCell-stickyHeader {
  position: static;
}

.print [class*="makeStyles-dataCotainer"] {
  white-space: nowrap;
}

.print [class*="makeStyles-wrapIcon"] {
  display: inline-block;
}

.print [class*="makeStyles-wrapIcon"] .MuiSvgIcon-fontSizeSmall {
  vertical-align: middle;
}
/* .MuiGrid-container {
  flex-wrap: unset !important;
} */

@media print {

  #root>[class*="MuiBox-root"],
  .printBtnSection {
    display: none !important;
  }

  #root>[class*="makeStyles-background"] {
    height: auto;
    background: no-repeat;
  }
}

.grecaptcha-badge {
  visibility: hidden;
  pointer-events: none;
}